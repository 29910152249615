<template>
  <v-container class="px-10" fluid>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="linkRoute"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
        <h3 class="pageHeadline" t-data="page-headline">News</h3>
          <v-btn
            id="add-btn"
            v-if="hasPermCreate()"
            large
            color="primary600Color"
            @click="create"
            class="white-text-button button-bold"
            t-data="create-btn"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Create News
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar v-model="isCreateFail" :content="snackbarFail" type="error"></snack-bar>
    <preview
      :show="isPreview"
      :close-preview="closePreview"
      :org-name="previewOrgName"
      :data-object="newsObject"/>

    <v-diglog-confirm
      :show="isDelete"
      :subTitle="subTitle"
      :close="closeConfirm"
      :buttonText="buttonConfirmText"
      :confirm="AcceptConfirm"
    />

    <!-- Bulk action -->
    <div v-show="false">
      <v-row v-if="hasPermEdit()">
        <v-col cols="3" sm="3">
          <v-select
            v-model="forceUpdateSelect"
            :items="updateItems"
            label="Force Update Status"
            @input="handleUpdateChange"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="3" sm="3">
          <v-btn
            large
            color="primary"
            :disabled="isBulkUpdate"
            @click="bulkUpdate"
            >Update</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col t-data="search-panel" cols="12" sm="4">
            <search :search="search" label="Search by Title" @input="textSearch" @clear="clearSearch()"></search>
          </v-col>
          <v-col cols="12" sm="6"></v-col>
          <v-col cols="12" sm="2" class="d-flex justify-end align-center" t-data="filter-panel">
            <filter-dropdown @apply="handleApplyFilter()"></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="newsList"
          :loading="isLoading"
          :options.sync="options"
          :single-select="singleSelect"
          :items-per-page="pageSize"
          :server-items-length="total"
          :footer-props="{'items-per-page-options': itemsPerPage}"
          item-key="id"
          :sort-desc="true"
          @input="selectId"
          show-select
          class="elevation-1 custom-table"
          fixed-header
          style="width: 100%"
          :height="windowSize.y - heightOptions.header - heightOptions.tableFooter"
        >
          <template v-slot:item.action="{item}">
            <div>
              <v-icon
                v-if="hasPermCreateByOrgs(item.orgs)"
                t-data="duplicate-action"
                class="px-2 action-icon"
                @click="duplicate(item.id)"
                >mdi-plus-box-multiple-outline</v-icon
              >
              <v-icon
                v-else
                t-data="empty-icon"
                class="px-2 action-icon transparent-icon"
              ></v-icon>
              <v-icon
                t-data="preview-action"
                class="px-2 action-icon"
                @click="preview(item)"
                >mdi-magnify</v-icon>
              <v-icon
                t-data="edit-action"
                class="px-2 action-icon"
                @click="edit(item.id)"
                >mdi-pencil-outline</v-icon
              >
              <v-icon
                v-if="hasPermDeleteByOrgs(item.orgs)"
                t-data="delete-action"
                class="px-2 action-icon"
                @click="showDeletePopup(item.id)"
                >mdi-trash-can-outline</v-icon
              >
              <v-icon
                v-else
                t-data="empty-icon"
                class="px-2 action-icon transparent-icon"
              ></v-icon>
            </div>
          </template>
          <template v-slot:item.contents="{item}" style="width: 270px">
            <v-list-item-content
              class="d-inline-block text-truncate"
              style="width: 270px"
            >
              <v-list-item-title style="padding: 2px">{{
                item.contents[0].code === 'th'
                  ? item.contents[0].title
                  : item.contents[1].title
              }}</v-list-item-title>
              <v-list-item-subtitle style="padding: 2px">{{
                item.contents[0].code === 'en'
                  ? item.contents[0].title
                  : item.contents[1].title
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template v-slot:item.is_publish="{item}">
            <publish-status-chip :is-publish="item.is_publish"></publish-status-chip>
          </template>
          <template v-slot:item.period_start_date="{item}">{{ !!item.period_start_date ? dateFmt(item.period_start_date) : '' }}</template>
          <template v-slot:item.period_end_date="{item}">{{ !!item.period_end_date ? dateFmt(item.period_end_date) : '' }}</template>
          <template v-slot:item.update_date="{item}">{{ !!item.update_date ? dateFmt(item.update_date) : '' }}</template>
          <template v-slot:item.update_by_user="{item}"><username-status-text :user="item.update_by_user" /></template>
          <template v-slot:item.status="{item}">
            <v-switch
              t-data="status-switch"
              color="primary200Color"
              v-model="item.status"
              :disabled="!hasPermEditByOrgs(item.orgs)"
              :false-value="1"
              :true-value="0"
              class="mb-0 pb-0 custom-switch"
              @change="e => handleSwitchStatusChange(e, item)"
            ></v-switch>
          </template>
          <template v-slot:item.orgs="{item}">{{ listBranch(item.orgs) }}</template>
          <template v-slot:item.category="{item}">{{
            convertCategory(item.category.contents)
          }}</template>
    </v-data-table>
      </v-layout>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import {
  convertDate,
  hasPermision,
  searchHandler,
  snackbarSuccessContent,
  snackbarFailContent,
  hasPermissionsByOrgs
} from '../../helper/helper'
import Confirm from '../../components/AlertConfirm'
import Search from '../../components/Search'
import FilterDropdown from './FilterDropdown'
import SnackBar from '@/components/SnackBar'
import {actionEnum} from '@/constants/constants'
import PublishStatusChip from '@/components/PublishStatusChip'
import UsernameStatusText from '@/components/UsernameStatusText'
import Preview from '@/components/news/Preview'

export default {
  components: {
    'filter-dropdown': FilterDropdown,
    'search': Search,
    'v-diglog-confirm': Confirm,
    Preview,
    PublishStatusChip,
    SnackBar,
    UsernameStatusText,
  },
  name: 'list',
  data() {
    return {
      pageName: 'news',
      heightOptions: {
        header: 312,
        tableFooter: 59,
      },
      windowSize: {
        x: 0,
        y: 0,
      },
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: 'created',
      subTitle:
        'This content will be deleted in both Thai and English.\nAre you sure you want to delete this content?',
      forceUpdateSelect: '',
      statusSelect: '',
      statusItems: [
        {text: 'All', value: ''},
        {text: 'Active', value: 0},
        {text: 'Inactive', value: 1},
        {text: 'Waiting', value: 2}
      ],
      deleteID: '',
      buttonConfirmText: 'Delete',
      previewOrgName: '',
      updateItems: [
        {text: 'Active', value: 0},
        {text: 'Inactive', value: 1}
      ],
      options: {},
      page: 1,
      sortBy: 'update_date',
      sortDesc: false,
      pageSize: 25,
      search: '',
      linkRoute: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/'
        },
        {
          text: 'News',
          disabled: true,
          href: '/'
        }
      ],
      singleSelect: false,
      selected: [],
      updateStatus: '',
      status: '',
      categorySelected: '',
      categoryStatus: '',
      itemsPerPage: [25, 50, 100],
      headers: [
        {text: 'Title', align: 'left', value: 'contents', sortable: false, width: 300},
        {text: 'Branch', align: 'center', value: 'orgs', sortable: false, width: 80},
        {text: 'Category', align: 'center', value: 'category', sortable: false, width: 150},
        {text: 'Publish Status', align: 'center', value: 'is_publish', sortable: false, width: 140},
        {text: 'Publish Start Date/Time', align: 'left', value: 'period_start_date', sortable: false, width: 150},
        {text: 'Publish End Date/Time', align: 'left', value: 'period_end_date', sortable: false, width: 150},
        {text: 'Updated Date/Time', align: 'left', value: 'update_date', sortable: false, width: 196},
        {text: 'Updated By', align: 'left', value: 'update_by_user', sortable: false, width: 162},
        {text: 'Status', align: 'center', value: 'status', sortable: false, width: 70},
        {text: 'Actions', align: 'center', value: 'action', sortable: false, width: 165},

      ]
    }
  },
  computed: {
    load() {
      return this.$store.state.news.load
    },
    items() {
      return this.$store.state.news.items
    },
    totalItem() {
      return this.$store.state.news.total
    },
    categoryItems() {
      return this.$store.state.news.category
    },
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    ...mapState('news', [
      'isDelete',
      'isLoading',
      'isPreview',
      'newsList',
      'newsObject',
      'orgList',
      'total',
      'updateIdList',
    ]),
    isBulkUpdate() {
      return this.updateIdList.length == 0 || this.forceUpdateSelect === ''
    }
  },
  async mounted() {
    await this.setListOrgInfo()
    this.getCategory()

    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle

    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setTextSearch('')
    this.resetList()
    next()
  },
  methods: {
    dateFmt: date => convertDate(date),
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    selectStatus() {
      this.setFilterStatus(this.statusSelect)
      this.fetch()
    },
    textSearch(data) {
      this.search = data
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        this.setTextSearch(this.search.trim())
        this.options.page = 1
        this.page = 1
        await this.fetch()
      })
    },
    hasPermCreate() {
      return hasPermision('CanAddNews')
    },
    hasPermEdit() {
      return hasPermision('CanEditNews')
    },
    hasPermCreateByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanAddNews', codenames)
    },
    hasPermEditByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanEditNews', codenames)
    },
    hasPermDeleteByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanDeleteNews', codenames)
    },
    clearSearch() {
      this.search = ''
      this.options.page = 1
      this.page = 1
      this.fetch()
    },
    closeConfirm() {
      this.setDeletePopup(false)
    },
    convertCategory(categories) {
      return categories.find(category => {
        if (category.code === 'th') return category.title
      }).title
    },
    async AcceptConfirm() {
      const isSuccess = await this.deletePkg()
      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.REMOVED)
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    create() {
      this.$router.push({name: 'news-create'})
    },
    async preview(row) {
      await this.setPreview(row.id)
      const filteredOrg = row.orgs.map(
        org => this.orgList.all.find(ob => ob.codename === org.org_codename)
      )
      this.previewOrgName = filteredOrg.map(o => o.name).join(', ')
    },
    closePreview() {
      this.setShowPreview(false)
    },
    edit(item) {
      this.$router.push({name: 'news-edit', params: {id: item}})
    },
    duplicate(item) {
      this.setDuplicateId(item)
      this.$router.push({name: 'news-create'})
    },
    async deleteItem(id) {
      this.isDelete = true
      this.deleteID = id
    },
    showDeletePopup(id) {
      this.setDeleteId(id)
      this.setDeletePopup(true)
    },
    getCategory() {
      let allorg = []
      this.orgList.all.forEach(org => {
        allorg.push(org.codename)
      })
      this.setOrgFilterCategory(allorg)
      this.fetchCategory()
    },
    selectId(event) {
      this.selected = event

      this.setSelectedId(event)
    },
    filter() {
      this.options.page = 1
      this.page = 1
      this.selected = []
      this.fetch(this.page)
    },
    async bulkUpdate() {
      this.update(this.forceUpdateSelect)
      this.selected = []
    },
    handleUpdateChange(event) {
      this.updateStatus = event
    },
    handleStatusChange(event) {
      this.options.page = 1
      this.page = 1
      this.selected = []
      this.status = event
      this.fetch(this.page)
    },
    async handleSwitchStatusChange(event, row) {
      this.setSelectedId([row])
      const isSuccess = await this.update(event)
      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.STATUS_UPDATED)
      } else {
        this.showFailSnackbar(actionEnum.STATUS_UPDATED)
      }
    },
    handleCategoryChange() {
      this.setFilterCategory(this.categorySelected)
      this.fetch()
    },
    getTextStatus(status) {
      if (status === 0) return 'Active'
      if (status === 2) return 'Waiting'
      else return 'Inactive'
    },
    getStatusColor(status) {
      if (status === 0) return 'green'
      if (status === 2) return 'yellow accent-4'
      else return 'red'
    },
    convertDate(date) {
      return convertDate(date)
    },
    listBranch(orgs) {
      return orgs.map(org => org.org_codename.toUpperCase()).join(', ')
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    ...mapActions('news', [
      'deletePkg',
      'fetch',
      'fetchCategory',
      'fetchNews',
      'resetList',
      'setDeleteId',
      'setDeletePopup',
      'setDuplicateId',
      'setFieldSort',
      'setFilterCategory',
      'setFilterStatus',
      'setListOrgInfo',
      'setOrgFilterCategory',
      'setPage',
      'setPageSize',
      'setPreview',
      'setSelectedId',
      'setShowPreview',
      'setSort',
      'setTextSearch',
      'update',
      'updateAllStatus',
      'updatePkg',
    ])
  },
  watch: {
    isCreateSuccess() {
      if (!this.isCreateSuccess) this.snackFullContent = ''
    },
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0] === 'contents' ? 'content' : sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.snackbar-width::v-deep > .v-snack__wrapper {
    min-width: 400px;
}
</style>
<template>
  <quick-preview
    :is-preview="show"
    :show-close-btn="true"
    @input="closePreview"
  >
    <template v-slot:content-en>
      <preview-content
        :org-name="orgName"
        :data-object="dataObject"
        :title="enContentObject.title"
        :thumbnail="enContentObject.thumbnail"
        :description="enContentObject.description"
      />
    </template>
    <template v-slot:content-th>
      <preview-content
        :org-name="orgName"
        :data-object="dataObject"
        :title="thContentObject.title"
        :thumbnail="thContentObject.thumbnail"
        :description="thContentObject.description"
      />
    </template>
  </quick-preview>
</template>
<script>
import QuickPreview from '@/components/QuickPreview'
import PreviewContent from '@/components/news/PreviewContent'

export default {
  name: 'Preview',
  components: {
    QuickPreview,
    PreviewContent
  },
  props: {
    show: {type: Boolean, required: true},
    closePreview: {type: Function},
    dataObject: {type: Object},
    orgName: {type: String}
  },
  data() {
    return {
      activeTab: 'th'
    }
  },
  computed: {
    enContentObject() {
      return this.dataObject.contents.filter(obj => {
        return obj.code == 'en'
      })[0]
    },
    thContentObject() {
      return this.dataObject.contents.filter(obj => {
        return obj.code == 'th'
      })[0]
    }
  }
}
</script>

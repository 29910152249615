<template>
  <filter-button
    :count-filtered="countFiltered"
    :disabled-clear="!isClearFilter"
    :disabled-apply="!isChangeFilter"
    @cancel="handleCancelFilter"
    @clear="handleClearFilter"
    @apply="handleSearchFilter"
  >
    <template v-slot:content>
      <v-list-item class="ml-5 mr-5 fixWidth d-block">
        <v-multiple-selected
          title="Branch"
          placeholder="All Branches"
          :branch="orgList.all"
          :selectedBranch="orgList.selected"
          :syncData="syncListSelectedData"
          :rule="() => true"
          :multiple="true"
          :is-changed="isChanged"
          :is-disabled="hasOneOrg"
          @resetIsChanged="isChanged = false"
        ></v-multiple-selected>
      </v-list-item>

      <v-list-item class="ml-2 mr-2 mt-2">
        <div class="d-flex flex-column justify-start align-start">
          <p class="mb-0 filterContentTitle sub-body-bold">Category</p>
          <template v-for="(item, i) in categoryItems">
            <v-checkbox
              color="secondary"
              :key="i"
              v-model="categorySelected"
              :label="item.text"
              :value="item.value"
              class="mt-0 filterContent body-regular"
              hide-details
            ></v-checkbox>
          </template>
        </div>
      </v-list-item>
    </template>
  </filter-button>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import MultipleSelected from '@/components/MultipleSelected'
import FilterButton from '@/components/FilterButton'
import {convertSelectedData} from '@/helper/helper'
var _ = require('lodash')

export default {
  name: 'FilterDropdown',
  data: () => ({
    categorySelected: [],
    countFiltered: 0,
    isChanged: false,
    appliedBranches: [],
    appliedCategories: []
  }),
  components: {
    FilterButton,
    'v-multiple-selected': MultipleSelected
  },
  computed: {
    categoryItems () {
      return this.$store.state.news.category
    },
    isClearFilter() {
      const changedCategory = !_.isEqual(this.categorySelected, [])
      const changedBranch = !_.isEqual(this.orgList.selected, [])
      if (this.hasOneOrg) return changedCategory
      return changedCategory || changedBranch
    },
    isChangeFilter() {
      const changedCategory = !_.isEqual(this.categorySelected, this.appliedCategories)
      const changedBranch = !_.isEqual(this.orgList.selected, this.appliedBranches)
      if (this.hasOneOrg) return changedCategory
      return changedCategory || changedBranch
    },
    hasOneOrg() {
      return this.orgList.all.length === 1
    },
    ...mapState('news', ['newsObject', 'orgObject', 'orgList'])
  },
  methods: {
    sumFiltered() {
      const sumBranch = this.hasOneOrg || this.orgList.selected.length <= 0 ? 0 : 1
      const sumCategory = this.categorySelected.length > 0 ? 1 : 0
      return sumBranch + sumCategory
    },
    syncListSelectedData(data) {
      const allBranch = data !== null && data.length === 0
      if (allBranch) {
        let allorg = []
        this.orgList.all.forEach(org => {
          allorg.push(org.codename)
        })

        this.orgList.selected = []
        this.orgList.selected = convertSelectedData(
          data,
          this.orgList.all,
          this.orgList.selected
        )
        this.setOrgFilterCategory(allorg)
      } else {
        this.orgList.selected = []
        this.orgList.selected = convertSelectedData(
          data,
          this.orgList.all,
          this.orgList.selected
        )
        this.setOrgFilterCategory(data)
      }

      this.fetchCategory()
    },
    handleSearchFilter() {
      this.$emit('apply')
      this.appliedBranches = this.orgList.selected
      this.appliedCategories = this.categorySelected
      this.setFilterCategory(this.categorySelected)
      this.fetch()
      this.countFiltered = this.sumFiltered()
    },
    handleClearFilter() {
      if (!this.hasOneOrg) this.orgList.selected = []
      this.categorySelected = []
      this.isChanged = true
    },
    handleCancelFilter() {
      if (!this.hasOneOrg) {
        this.orgList.selected = this.appliedBranches
        this.isChanged = true
      }
      // wait for multiple select render
      this.$nextTick(() => {
        this.categorySelected = this.appliedCategories
        this.setFilterCategory(this.categorySelected)
        this.countFiltered = this.sumFiltered()
      })
    },
    ...mapActions('news', ['fetch', 'fetchCategory', 'setFilterCategory', 'setOrgFilterCategory'])
  }
}
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" t-data="profile-image" class="py-0">
        <v-img :src="thumbnail" max-height="501"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-4">
        <h4 class="mt-0 card-title" t-data="info-title">
          {{ title }}
        </h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="body-regular secondaryTextColor--text pt-2 pb-0" t-data="publish-date">
        <v-icon left light size="18">
          mdi-calendar-month
        </v-icon>
        {{ dateFmt(dataObject.period_start_date) }} -
        {{ dateFmt(dataObject.period_end_date) }}
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="body-regular secondaryTextColor--text pt-2 pb-0" t-data="org-name">
        <v-icon left light size="18">
          mdi-office-building
        </v-icon>
        {{ orgName }}
      </v-col>
    </v-row>
    <div
      class="content-detail mt-6 body-regular primaryTextColor--text"
      t-data="description"
      v-html="description"
    ></div>
  </div>
</template>

<script>
import {convertDateFullMonthDate} from '@/helper/helper'

export default {
  name: 'PreviewContent',
  props: {
    dataObject: {type: Object},
    orgName: {type: String},
    title: {type: String},
    thumbnail: {type: String},
    description: {type: String}
  },
  methods: {
    dateFmt: date => convertDateFullMonthDate(date)
  }
}
</script>
